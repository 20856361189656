<template>
<v-dialog :fullscreen="$vuetify.breakpoint.mobile"
          :value="value" :style="{ borderRadius: '50px' }"
          scrollable max-width="99%"
          persistent>          
  <v-card class="d-flex flex-column">
    <v-card-title class="primary white--text">
      {{getTitle}}
      <v-spacer/>
      <v-btn text elevation="0" color="white" small @click="fechar">
        Fechar <v-icon>mdi-close</v-icon>
      </v-btn> 
    </v-card-title>
    <v-divider/>
    <v-card-text class="py-4 flex-grow-1" v-if="value">
      <v-textarea
        v-model="respostaEdicao"
        label="Edite o parágrafo"
        auto-grow
      ></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn color="success" @click="fechar">Cancelar</v-btn>
      <v-btn color="primary" @click="salvarParagrafoUnico">Salvar</v-btn>
    </v-card-actions>
  </v-card>
  <v-overlay :value="loading" absolute>
    <v-progress-circular
      indeterminate
      color="primary"
      class="ma-4"
    ></v-progress-circular>
  </v-overlay>
</v-dialog>
</template>

<script>
import {create as createDocGerado} from "../../../api/docGerado";
import {ApiError} from "../../../api/core";

export default {
  name: "EditParagrafoDialog",
  props: {
    paragrafo: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    'value': {
      handler(newValue) {
        if (newValue){
          this.respostaOriginal = JSON.parse(JSON.stringify(this.paragrafo.resposta));
          this.respostaEdicao = JSON.parse(JSON.stringify(this.paragrafo.resposta));
        }
      }
    },
  },   
  data() {
    return {
      loading: false,
      respostaOriginal: JSON.parse(JSON.stringify(this.paragrafo.resposta)),
      respostaEdicao: JSON.parse(JSON.stringify(this.paragrafo.resposta)),
    };
  },
  methods: {
    async salvarParagrafoUnico(){
      try {       
        this.loading = true;     
        this.paragrafo.resposta = this.respostaEdicao;
        this.paragrafo.regerarIa = 'N';
        const retorno = await createDocGerado(this.paragrafo);
        this.paragrafo.dataGeracao = retorno.dados.dataGeracao;
        this.$emit('input', false);
        this.$toast.success(`Parágrafo atualizado com sucesso.`, {
          timeout: 3000
        })
      } catch (err) {
        this.paragrafo.resposta = this.respostaOriginal;
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
          timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível salvar o parágrafo.`, {
            timeout: 6000
          })
        }
      } finally {
        this.loading = false;
      }  
    },
    fechar(){
      this.paragrafo.resposta = this.respostaOriginal;
      this.$emit('input', false);
    }
  },
  computed: {
    getTitle() {
      return 'Editar Parágrafo';
    }
  },
};
</script>