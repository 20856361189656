<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <search-box
            label="* Documento"
            popup-label="Selecione um Documento"
            :id.sync="value.idDocumento"
            :descricao.sync="value.descricaoDocumento"
            :hidden-clear="true"
            :value="documentos"
            :loader-fn="loaderDocumentos"
            item-key="id"
            item-text="descricao"
            :rules="[
                this.$validators.notNullOrUndefined
            ]">
          <template v-slot:empty>
            <p class="ma-2">
              Nenhum Documento encontrado
            </p>
          </template>
        </search-box>
      </v-col>
      <v-col cols="12" md="2" class="d-flex justify-end">
        <v-btn color="success" class="mr-2" dark @click="gerarDocumento($event, tipoDeGeracao)" :disabled="isLoading">{{tituloBotaoGeracao}}</v-btn>
      </v-col>
      <v-col cols="12" v-if="!this.value.idDocumento">
        <p>Escolha o documento a ser gerado.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-timeline
          align-top
          dense>
          <v-timeline-item v-for="(paragrafo,index) of paragrafosGerados" :key="index"
            color="success" icon="mdi-file-document-outline">
            <v-card outlined>
              <v-card-title class="font-weight-bold text-h7 ponto-scroll">
                {{paragrafo.descricaoParagrafo}}
              </v-card-title>
              <v-card-text :style="{ whiteSpace: 'pre-line' }">
                <template v-if="paragrafo.loading && paragrafo.resposta ===''">
                  <img src="../../../assets/loading.gif" alt="Processando..." width="50"/>
                </template>
                <template v-else>
                  {{paragrafo.resposta}}
                </template>
              </v-card-text>
              <v-divider/>
              <v-card-actions class="d-flex">
                <v-row>
                  <v-col cols="12" md="10" class="d-flex justify-start align-center">
                    <v-icon class="ml-2" small>mdi-clock-outline</v-icon>
                    <span class="ml-2">{{paragrafo.dataGeracao !== '' ? 'Data última geração:' : 'Não gerado'}}</span>
                    <span style="color:#388E3C" class="ml-2 font-weight-bold">{{paragrafo.dataGeracao}}</span>
                  </v-col>
                  <v-col cols="12" md="2" class="d-flex justify-end align-center">
                    <v-btn color="error" align="right" class="mr-2" dark small @click="abrirEdicaoParafrafo(paragrafo)" :disabled="isLoading">Editar</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
    <!-- Componente de edição do parágrafo -->
    <EditParagrafoDialog
      v-if="paragrafoSelecionado !== null"
      :paragrafo="paragrafoSelecionado"
      v-model="editParagrafoDialog"
    />
  </div>
</template>

<script>
import {findAll as findAllDocumentos} from "../../../api/documento";
import {findOne as findDocGerado, create as createDocGerado} from "../../../api/docGerado";
import LookupDominio from "../common/LookupDominio";
import OrientacoesPreenchimentoPrompt from "../common/OrientacoesPreenchimentoPrompt";
import EditParagrafoDialog from "../dialogs/EditParagrafoDialog";
import SearchBox from "../common/SearchBox";
import {ApiError} from "../../../api/core";

export default {
  name: "DocGeradoForm",
  components: {SearchBox, LookupDominio, OrientacoesPreenchimentoPrompt, EditParagrafoDialog},
  props: {
    value: {
      type: Object
    },
    formValid: {
      type:Boolean
    }
  },
  data() {
    return {
      error: null,
      isLoading: false,      
      paragrafosGerados: [],
      docJaFoiGerado: false,
      paragrafoSelecionado: null,
      editParagrafoDialog: false,
    }
  },
  computed: {
    paragrafosGeradosComputed() {
      return this.paragrafosGerados.map(parafrago => ({
        ...parafrago
      }));
    },
    documentos() {
      if (this.value.idDocumento == null) return null;
      return {
        id: this.value.idDocumento,
        descricao: this.value.descricaoDocumento
      }
    },
    validators() {
      return this.$validators;
    },
    tituloBotaoGeracao(){
      return (this.docJaFoiGerado ? 'Regerar' : 'Gerar');
    },
    tipoDeGeracao(){
      return (this.docJaFoiGerado ? 'R' : 'G') ;
    }
  },
  watch: {
    'value.idDocumento': {
      handler(newValue) {
        this.buscarDocGerado(newValue);
      }
    },
  },  
  methods: {
    abrirEdicaoParafrafo(paragrafo) {
      this.paragrafoSelecionado = paragrafo;
      this.editParagrafoDialog = true;
    },
    loaderDocumentos(page, search) {
      return findAllDocumentos(page, {
        nome: search
      })
    },
    async buscarDocGerado(idDoc) {
      try {
        this.error = null;
        this.controlaLoading(true);
        const page = await findDocGerado(this.value.id, idDoc);
        this.paragrafosGerados = page.dados;
        this.docJaFoiGerado = page.docJaFoiGerado;
      } catch (err) {
        this.error = err;
        console.log(err);
        this.paragrafosGerados = [];        
        if (err instanceof ApiError) {
          this.$toast.error(err.message, {
            timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível verificar se o ${this.value.descricaoDocumento} já foi gerado para essa licitação.`, {
            timeout: 6000
          })
        }   
      } finally {
        this.controlaLoading(false);
      }
    },
    async gerarDocumento(event, tipoGeracao){
      event.preventDefault();
      try {
        if (!this.value.idDocumento) throw new ApiError('exe',`Informe o documeto a ser gerado.`);
        this.controlaLoading(true);
        let index = 0;
        for (let paragrafo of this.paragrafosGerados) {
          this.scrollToTextarea(index);
          paragrafo.loading = true;
          if (tipoGeracao==='R') paragrafo.regerarIa = 'S';
          else paragrafo.regerarIa = 'N';
          paragrafo.resposta = '';
          const retorno = await createDocGerado(paragrafo);
          await this.setaResposta(paragrafo, retorno.dados, tipoGeracao);
          paragrafo.loading = false;
          index++;
        }
        this.scrollToTextarea(0);
        this.docJaFoiGerado = true;
        this.$toast.success(`Documento ${(tipoGeracao === 'R' ? 're' : '') + 'gerado'} com sucesso.`, {
          timeout: 3000
        })
      } catch (err) {
        if(err instanceof ApiError) {
          this.$toast.error(err.message, {
          timeout: 6000
          })
        } else {
          this.$toast.error(`Não foi possível ${(tipoGeracao === 'R' ? 're' : '') + 'gerar'} o documento.`, {
            timeout: 6000
          })
        }
      } finally {
        this.controlaLoading(false);
      }    
    },
    async setaResposta(paragrafo, retornoResposta, tipoGeracao) {
      paragrafo.resposta = '';
      paragrafo.paragrafoNaoFoiGerado = true;
      if (tipoGeracao !== 'S'){
        const partes = this.dividirTextoEmPartes(retornoResposta.resposta, 5);
        for (let i = 0; i < partes.length; i++) {
          paragrafo.resposta += partes[i] + ' ';
          await this.sleep(100);  // Atraso de 100ms entre cada palavra
        }
      } else {
        paragrafo.resposta = retornoResposta.resposta;
      }
      paragrafo.paragrafoNaoFoiGerado = retornoResposta.paragrafoNaoFoiGerado;
      paragrafo.dataGeracao = retornoResposta.dataGeracao;
    },
    dividirTextoEmPartes(texto, numeroDePalavras) {
      const palavras = texto.split(' '); // Divide o texto em um array de palavras
      const partes = [];
      let indice = 0;
      while (indice < palavras.length) {
        const parte = palavras.slice(indice, indice + numeroDePalavras).join(' ');
        partes.push(parte);
        indice += numeroDePalavras;
      }
      return partes;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    scrollToTextarea(index) {
      this.$nextTick(() => {
        const textareaElement = this.$el.querySelectorAll('.ponto-scroll')[index];
        if (textareaElement) {
          textareaElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    },
    controlaLoading(valor){
      console.log('controlaLoading', valor);
      this.isLoading = valor;
      this.$emit('onExecuta',valor);
    }
  }
}
</script>

<style scoped>
.custom-textarea-label .v-label,
.custom-textarea-label .v-label.v-label--active,
.custom-textarea-label .v-label.v-label--single-line,
.custom-textarea-label .v-label.v-label--active.v-label--single-line {
  font-size: 1.25rem !important;
  font-weight: bold !important;
  color: #000 !important;
}
.em-execucao {
  pointer-events: none; /* Prevent interaction when loading */
  opacity: 0.5; /* Optional: Make the container semi-transparent during loading */
}
</style>