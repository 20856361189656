<template>
  <v-col class="custom-rounded-border" cols="12" sm="12">
    <v-row class="custom-titulo">
      <v-col cols="10" sm="10" class="font-weight-bold">
        {{title}} 
        <v-icon color="secondary" @click="show = !show">{{show ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
      </v-col>
      <v-col cols="2" sm="2" class="d-flex align-end justify-end" v-if="action">
        <v-btn small color="secondary" @click="action">
          <v-icon :color="iconColor">mdi-{{ icon }}</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="2" sm="2" class="d-flex align-end justify-end" v-else>  
        <v-icon :color="iconColor">mdi-{{ icon }}</v-icon>
      </v-col>
    </v-row>
    <v-row class="topo" v-if="show">
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
      <v-col cols="12" sm="12">
        <slot></slot>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'CardPersonalizado',
  props: {
    title: {
      type: String,
      required: true,
    },
    showConteudo: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconColor: {
      type: String,
      default: 'white',
    },
    action: {
      type: Function,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      show: true,
    }
  },
  mounted() {
    this.show = (this.showConteudo ? true : false);
  },
};
</script>

<style scoped>
  .topo {
    padding-top: 5px !important;
  }
  .custom-rounded-border {
    border: 1px solid rgba(0, 0, 0, 0.12); /* Similar to v-card border */
    border-radius: 8px;                   /* Border radius for rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06); /* Shadow for floating effect */
    /* padding: 16px;                       Padding for inner spacing */
    background-color: white;             /* Background color */
  }
  .custom-titulo {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #f0f0f0;
  }
</style>
